import React from 'react'
import { graphql } from 'gatsby'
import { parseImageUrl } from '@conradlin/notabase/src/utils'
import Layout from '../components/layout'
import Helmet from 'react-helmet'


export default ({ data }) => {
  const { posts: { title, tags, publish_date, html, url, slug, desc, color, cover_image } } = data
  let coverimageURL = parseImageUrl(cover_image[0], 1000, slug)
  const fullurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout>
       <Helmet>
        <title>{title}</title>
        <link href={fullurl} rel="canonical"/>
        <meta name="description" content={desc} />
        <meta property="og:site_name" content="Balance ton hacker"/>
        <meta property="og:title" content={title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:locale" content="fr_FR"/>
        <meta property="og:description" content={desc}/>
        <meta property="og:image" content={coverimageURL}/>
        <meta property="og:url" content={fullurl}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={desc}/>
        <meta name="twitter:image" content={coverimageURL}/>
      </Helmet>
      <div id = "main">
        <a href="/blog#main">Retour&nbsp;<span className="icon fa-backward"></span></a>
        <h1 className="mt-4">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div className="text-center my-3">
          <a href="/blog#main" className="button">Plus d'articles</a>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    posts(slug: { eq: $slug }) {
      html
      title
      tags
      publish_date{
        startDate(formatString: "DD-MM-YYYY", fromNow: false)
      }
      slug
      url
      desc
      color
      cover_image
    }
  }
`